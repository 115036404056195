import Footer from "../components/Footer";
import Header from "../components/Header";
import Earnsection from "./Earnsection";
import Faqsection from "./Faqsection";

export default function Welcomescreen() {
    return (
        <div className="home_page_mian_div welcome_page">
            <div className="bg-img-div">
                <Header />
                <div className="optimize_crm">
                    <div className="container">
                        <div className="optimize_crm_content">
                            <h1 className="h1 text-center">
                                Thanks for installing Teachers Aid
                            </h1>

                            <h6 className="text-center welcomeh6">
                                Please watch the video above to get started
                            </h6>
                            <div className="optimize_image_box">
                                <img src="/images/VideoContainer.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-section">
                <div className="container">
                    <div className="global-padding pb-0">
                        <h1 className="text-center">Get Started with Teacher's Aid</h1>
                        <h6 className="welcomeh6 text-center">Follow these simple steps to start using Teacher's Aid and unlock better <br /> organization and communication for your classroom.</h6>
                    </div>
                </div>
            </div>

            <div className="grid_sections">
                <div className="container">
                    <div className="global-padding">
                        <div className="row">
                            <div className="col-lg-6 col-xl-7 my-auto">
                                <div className="img-left">
                                    <img src="/images/VideoContainer.svg" alt="video" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-5 my-auto">
                                <div className="grid_content ps-lg-2">
                                    <h1>Step 1</h1>
                                    <h2>Download & Install</h2>
                                    <p className="pt-3">Download and install the Teacher's Aid Chrome <br /> Extension from the Chrome Web Store.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid_sections">
                <div className="container">
                    <div className="global-padding">
                        <div className="row">
                            <div className="col-lg-6 col-xl-5 my-auto">
                                <div className="grid_content ps-lg-2">
                                    <h1 className="orange">Step 2</h1>
                                    <h2>Pin the Extension</h2>
                                    <p className="pt-3">Once installed, don’t forget to pin the Teacher's Aid <br /> Extension to your browser for easy access.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7 my-auto">
                                <div className="img-right">
                                    <img src="/images/VideoContainer.svg" alt="video" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid_sections">
                <div className="container">
                    <div className="global-padding">
                        <div className="row">
                            <div className="col-lg-6 col-xl-7 my-auto">
                                <div className="img-left">
                                    <img src="/images/VideoContainer.svg" alt="video" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-5 my-auto">
                                <div className="grid_content ps-lg-2">
                                    <h1 className="orange">Step 3</h1>
                                    <h2>Set Up Tags</h2>
                                    <p className="pt-3">Start by tagging your conversations to keep everything <br /> tidy and easy to find.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid_sections orange">
                <div className="container">
                    <div className="global-padding">
                        <div className="row">
                            <div className="col-lg-6 col-xl-7 my-auto">
                                <div className="img-left">
                                    <img src="/images/welcomegrid.svg" alt="Communication image" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-5 my-auto">
                                <div className="grid_content ps-lg-2">
                                    <h2>You’re all set!  <br />
                                    Enjoy seamless <br /> communication and <br /> management with <br /> Teacher's Aid.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Faqsection/>
            <Earnsection/>
            <Footer/>
        </div>
    )
}